<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container extra--padding mb-4">
      <page-loading :show="loading" />
      <featured-agent-search></featured-agent-search>
      <featured-agent-list></featured-agent-list>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import FeaturedAgentSearch from '@/components/featured-agent/search';
import FeaturedAgentList from '@/components/featured-agent/list';
import { mapState } from 'vuex';
import PageLoading from '@/components/content-loading/page-loading.vue';

export default {
  name: 'featured-agent',
  mixins: [DefaultHeadMixin],
  components: {
    PageLoading,
    DefaultLayout,
    FeaturedAgentSearch,
    FeaturedAgentList,
  },
  computed: {
    ...mapState({
      loading: (state) => state.featuredAgent.loading,
    }),
    breadcrumbs() {
      const currentName = this.$t('home.sidebarMenu.featuredAgent');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {},
  async mounted() {},
};
</script>

<style lang="scss" scoped></style>

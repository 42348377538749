<template>
  <div class="bulk--repost">
    <div v-if="searchQuery || (featuredAgents && featuredAgents.length > 0)">
      <div class="bulk--repost-desc">{{ $t('featuredAgent.desc') }}</div>
      <div class="bulk--repost-select">
        <div class="bulk--repost-input">
          <v-text-field
            :label="$t('featuredAgent.search')"
            :placeholder="$t('featuredAgent.placeholder.search')"
            outlined
            :hide-details="true"
            v-model="searchQuery"
            name="keyword"
            class="basic--input"
          ></v-text-field>
          <div v-show="searchQuery" class="reset--search">
            <button type="button" @click="resetSearch">
              <i class="ion-close"></i>
              <span class="reset--text">{{ $t('order.resetSearch') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';

require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'featured-agent-search',
  mixins: [HelperMixin, LodashMixin],
  components: {},
  props: [],
  data: () => ({
    debounceGetFeaturedAgents: null,
  }),
  computed: {
    ...mapState({
      loading: (state) => state.featuredAgent.loading,
      featuredAgents: (state) => state.featuredAgent.featuredAgents,
    }),
    searchQuery: {
      get() {
        return this.$store.state.featuredAgent.searchQuery;
      },
      set(value) {
        value = this.cleanInput(value);
        this.$store.commit('featuredAgent/SET_SEARCH_QUERY', value);
        this.debounceGetFeaturedAgents(value);
      },
    },
  },
  created() {
    this.debounceGetFeaturedAgents = this.debounce(this.getFeaturedAgents, 500);
  },
  async mounted() {
    await this.$store.dispatch('featuredAgent/restoreInitialState');
    await this.getFeaturedAgents(null);
  },
  methods: {
    async getFeaturedAgents(query) {
      this.$store.commit('featuredAgent/SET_SEARCH_QUERY', query);
      await this.$store.dispatch('featuredAgent/getPurchasedFeaturedAgents', {
        page: 1,
      });
    },
    resetSearch() {
      this.$store.commit('featuredAgent/SET_SEARCH_QUERY', null);
      this.$store.dispatch('featuredAgent/getPurchasedFeaturedAgents', { page: 1 });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';

.bulk--repost {
  .bulk--repost-desc {
    text-align: center;
    margin-bottom: 12px;
    font-size: $font-ml;
    @media #{$phones} {
      font-size: $font-md;
    }
  }

  .bulk--repost-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    .bulk--repost-input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 500px;
      max-width: 500px !important;
    }
  }

  .bulk--repost-option {
    display: flex;
    align-items: center;
    .bulk--repost-img {
      img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-right: 8px;
        @media #{$phones} {
          width: 60px;
          height: 60px;
        }
      }
    }
    .bulk--repost-title {
      font-family: Poppins-SemiBold;
      font-size: $font-ml;
      color: $color-text-black;
      margin-bottom: 6px;
      // wrap is the correct value, ignore the warning
      //noinspection CssInvalidPropertyValue
      text-wrap: wrap;
      @media #{$phones} {
        font-size: $font-sm;
      }
    }
    .bulk--repost-listing-id {
      color: $color-text-gray-2;
      margin-bottom: 6px;
      @media #{$phones} {
        font-size: $font-xs;
      }
    }
    .bulk--repost-price {
      color: $color-text-black;
      @media #{$phones} {
        font-size: $font-xs;
      }
    }
  }
}
</style>

<template>
  <div class="list--container">
    <div>
      <div
        v-if="searchQuery || (formattedFeaturedAgents && formattedFeaturedAgents.length > 0)"
        class="d-flex justify-end"
      >
        <router-link to="/featured-agent/beli" class="btn btn-primary buy--featured-agent">
          <span>{{ `+ ${$t('featuredAgent.btn.buy')}` }}</span>
        </router-link>
      </div>
      <div v-if="formattedFeaturedAgents && formattedFeaturedAgents.length > 0">
        <div class="row">
          <div
            v-for="(featuredAgent, index) in formattedFeaturedAgents"
            :key="`featured-agent-${index}`"
            class="col-12 col-sm-6 col-lg-4 px-2 d-flex align-stretch"
          >
            <v-card class="featured--agent-card">
              <div>
                <div class="fa--title">{{ featuredAgent.area }}</div>
                <div class="label--container justify-center">
                  <div
                    v-for="(entry, index) in featuredAgent.entries"
                    :key="`featured-agent-entry-${index}`"
                    class="label mr-2"
                  >
                    {{ entry.month_name }} {{ entry.year }}
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <pagination
          :push-state="false"
          :meta="meta"
          @changePage="changePage"
          :maxVisibleButtons="5"
          v-if="meta"
        />
      </div>
      <div class="d-flex justify-center" v-else-if="!loading">
        <div class="empty--container" v-if="!searchQuery">
          <div class="empty--text">
            Anda belum membeli agen pilihan. Silahkan beli slot agen pilihan baru.
          </div>
          <router-link to="/featured-agent/beli" class="btn btn-primary empty--buy">
            <span>{{ `+ ${$t('featuredAgent.btn.buy')}` }}</span>
          </router-link>
        </div>
        <div class="empty--container" v-else>
          <div class="empty--text">Tidak ditemukan agen pilihan berdasarkan hasil pencarian.</div>
          <div class="empty--buy"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import LodashMixin from '@/mixins/lodash';
import { mapGetters, mapState } from 'vuex';
import Pagination from '@/components/utils/fractal-pagination.vue';

export default {
  name: 'featured-agent-list',
  mixins: [HelperMixin, LodashMixin],
  components: { Pagination },
  props: [],
  data: () => ({}),
  computed: {
    ...mapState({
      meta: (state) => state.featuredAgent.meta,
      loading: (state) => state.featuredAgent.loading,
      searchQuery: (state) => state.featuredAgent.searchQuery,
    }),
    ...mapGetters({
      formattedFeaturedAgents: 'featuredAgent/formattedFeaturedAgents',
    }),
  },
  mounted() {},
  methods: {
    changePage(page) {
      this.$store.commit('featuredAgent/SET_PAGE', page);
      this.$store.dispatch('featuredAgent/getPurchasedFeaturedAgents', { page: page });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';

.list--container {
  .buy--featured-agent {
    margin-bottom: 20px;
  }
  .featured--agent-card {
    width: 100%;
    padding: 8px 12px;
    border-radius: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    //background-color: $color-beliruma-1-light;
    .fa--title {
      text-align: center;
      font-family: Poppins-SemiBold;
      font-size: $font-lg;
    }
    .area--name {
      text-align: center;
      margin-top: 4px;
    }
    .label--container {
      padding: 8px 8px 0 8px;
    }
    .label {
      font-size: $font-md;
      margin-bottom: 6px;
    }
  }
  .empty--container {
    max-width: 500px;
    .empty--text {
      text-align: center;
      font-family: Poppins-SemiBold, sans-serif;
      font-size: $font-lg;
      margin-top: 80px;
    }
    .empty--buy {
      width: fit-content;
      margin: 24px auto 80px auto;
      height: 48px;
    }
  }
}
</style>
